import {BuilderElement} from "@builder.io/sdk";
import React from "react";
import {Link} from "theme-ui";
import {BuilderBlocks} from "@builder.io/react";

export interface SocialButtonProps {
  text?: string;
  link?: string;
  openLinkInNewTab?: boolean;
  builderBlock: BuilderElement;
  image: React.ReactNode;
  title: React.ReactNode;
}

export const SocialButton = (props: SocialButtonProps) => {
  return (
      <Link
          role="button"
          href={props.link}
          target={props.openLinkInNewTab ? '_blank' : undefined}
          className={"link-underline link-underline-opacity-0"}
          style={{display: 'block', width: '145px'}}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
          {props.image &&
          <BuilderBlocks
           child
           parentElementId={props.builderBlock && props.builderBlock.id}
           blocks={props.image}
          />
          }
          {props.title &&
          <BuilderBlocks
           child
           parentElementId={props.builderBlock && props.builderBlock.id}
           blocks={props.title}
          />
          }
        </div>
      </Link>
  );
};

